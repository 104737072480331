import * as React from 'react'
import { BlogContext } from './BlogStore'
import clsx from 'clsx'

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

const CategoryItem = ({ id, children }) => {
  const { $selectedCategory, set$selectedCategory } = React.useContext(BlogContext)

  return (
    <li
      className={clsx($selectedCategory === id && 'font-bold', 'cursor-pointer mb-2 hover:opacity-80')}
      onClick={() => set$selectedCategory(id)}
    >
      {children}
    </li>
  )
}

const CategoryList = ({ categories }) => (
  <ul>
    <CategoryItem id={false}>
      All Topics
    </CategoryItem>

    {categories.map((category, key) => (
      <CategoryItem id={category?.seo.slug.current} key={key}>
        {category.title}
      </CategoryItem>
    ))}
  </ul>
)

export default CategoryList
