import * as React from 'react'
import * as css from './Sidebar.module.css'
import { graphql, useStaticQuery } from 'gatsby'
import { BlogContext } from './BlogStore'
import { MdArrowDropDown as Down, MdArrowDropUp as Up } from 'react-icons/md'
import CategoryList from './CategoryList'
import Search from './Search'
import Share from './Share'
import Subscribe from './Subscribe'
import clsx from 'clsx'

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

const Sidebar = ({ title }) => {
  const categories = useStaticQuery(query).allSanityBlogCategory.nodes

  const {
    $sidebarOpened, set$sidebarOpened,
    $selectedCategory,
  } = React.useContext(BlogContext)

  const category = categories
    .find(({ seo }) => seo.slug.current === $selectedCategory)

  return (
    <div className="sticky-below-header">
      <h1
        className="h3 technical-lg cursor-pointer flex items-center md:pointer-events-none"
        onClick={() => set$sidebarOpened(!$sidebarOpened)}
      >
        {title}
        {$sidebarOpened
          ? <Up className="text-[40px] md:!hidden" />
          : <Down className="text-[40px] md:!hidden" />
        }
      </h1>

      <div className={`${css.category} inline-block font-bold mt-4 md:!hidden`} key={category?.title || null}>
        {category?.title || 'All Topics'}
      </div>

      <div className={clsx(css.inner, !$sidebarOpened && '<md:!hidden')}>
        <CategoryList categories={categories} />
        <Search/>
        <Share/>
        <Subscribe/>
      </div>
    </div>
  )
}

export default Sidebar

const query = graphql`query Sidebar {
  allSanityBlogCategory {
    nodes {
      title
      seo { slug { current } }
    }
  }
}`
