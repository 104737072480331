import * as React from 'react'
import DefaultLayout from 'layouts/Default'
import { BlogProvider } from 'components/blog/BlogStore'
import Sidebar from 'components/blog/Sidebar'
import AllArticleList from 'components/blog/AllArticleList'
import TextWithBg from 'components/common/TextWithBg'
import { graphql, useStaticQuery } from 'gatsby'

const meta = {
	title: 'The FASTECH Energy Solutions Blog',
}

const BlogPg = () => {
	const { img } = useStaticQuery(query)

	return (
		<DefaultLayout {...meta}>
			<div className="ctnr padding grid gap-x-24 gap-y-16 md:grid-cols-[250px,1fr]">
				<BlogProvider>
					<aside>
						<Sidebar title={meta.title} />
					</aside>

					<AllArticleList />
				</BlogProvider>
			</div>

			<TextWithBg
				image={img}
				title="Start Designing Your Energy Infrastructure Project"
				description="Are you looking for a trusted partner to manage your upcoming energy project, facility upgrade or ongoing maintenance? Let’s work together."
				link={{
					label: 'Get started',
					url: '/contact',
				}}
			/>
		</DefaultLayout>
	)
}

export default BlogPg

// TODO: add content to blog settings schema

const query = graphql`
	query BlogPg {
		img: file(relativePath: { eq: "cta/bottom-cta.png" }) {
			childImageSharp {
				gatsbyImageData(placeholder: NONE)
			}
		}
	}
`
