import * as React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import CTALink from 'components/common/CTALink'
import SanityBlock from './SanityBlock'

const TextWithBg = ({ image, title, link, _rawContent, description }) => (
	<section className="padding-y relative bg-suede-black text-white">
		{image && (
			<GatsbyImage
				className="!absolute inset-0 opacity-20 grayscale"
				image={getImage(image.asset || image)}
				alt={title}
				draggable="false"
			/>
		)}

		<div className="padding-x richtext relative mx-auto max-w-[550px] text-center [&_a]:text-inherit">
			<h2 className="h1">{title}</h2>
			<SanityBlock body={_rawContent} />
			{!_rawContent && description && <p>{description}</p>}
			<CTALink {...link} />
		</div>
	</section>
)

export default TextWithBg
