import * as React from 'react'
import * as css from './Search.module.css'
import { IoIosSearch } from 'react-icons/io'
import { navigate } from 'gatsby-link'
import clsx from 'clsx'

const Search = ({ className }) => {
	const [$query, set$query] = React.useState(null)

	function handleChange({ target }) {
		set$query(target.value)
	}

	function handleKeyUp({ key }) {
		if (key !== 'Enter') return
		search()
	}

	function search() {
		!!$query && navigate(`/blog/search?query=${encodeURI($query)}`)
	}

	return (
		<div className={clsx(className, css.root, 'focus-within:ring-ft')}>
			{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
			<label htmlFor="search">
				<input
					id="search"
					name="search"
					className={`${css.input} input`}
					onChange={handleChange}
					onKeyUp={handleKeyUp}
					type="search"
					placeholder="Search"
				/>
			</label>

			<button className={`${css.btn} input-button`} onClick={() => search()} aria-label="Search">
				<IoIosSearch />
			</button>
		</div>
	)
}

export default Search
