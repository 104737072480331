import * as React from 'react'
import * as css from './Share.module.css'
import { EmailShareButton, FacebookShareButton, TwitterShareButton } from 'react-share'
import { IoPaperPlane } from 'react-icons/io5'
import { FaFacebookF, FaTwitter } from 'react-icons/fa'
import useSiteMetadata from 'hooks/useSiteMetadata'

const Share = () => {
  const shareUrl = useSiteMetadata().siteUrl + '/blog'

  return (
    <div className={css.root}>
      <div className={css.title}>Share</div>

      <ul className={css.list}>
        <li>
          <EmailShareButton url={shareUrl}>
            <IoPaperPlane/>
          </EmailShareButton>
        </li>
        <li>
          <FacebookShareButton url={shareUrl}>
            <FaFacebookF/>
          </FacebookShareButton>
        </li>
        <li>
          <TwitterShareButton url={shareUrl}>
            <FaTwitter/>
          </TwitterShareButton>
        </li>
      </ul>
    </div>
  )
}

export default Share
