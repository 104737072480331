import * as React from 'react'
import * as css from './AllArticleList.module.css'
import { BlogContext } from './BlogStore'
import ArticlePreview from './ArticlePreview'
import NoPosts from './NoPosts'

const AllArticleList = () => {
  const { allBlogs, $selectedCategory } = React.useContext(BlogContext)

  const blogs = !!$selectedCategory
    ? allBlogs.filter(({ category }) => $selectedCategory === category?.seo.slug.current)
    : allBlogs

  const [firstBlog, ...theRest] = blogs

  const limit = 6
  const [$limitArticles, set$limitArticles] = React.useState(true)

  return (
    <div>
      {firstBlog && <ArticlePreview post={firstBlog} large key={firstBlog.seo.slug.current} />}

      {!!theRest.length && <>
        <div className={css.list}>
          {theRest.map((blog, key) => (!$limitArticles || key < limit) && (
            <ArticlePreview post={blog} key={blog.seo.slug.current} />
          ))}
        </div>

        {(theRest.length > limit) && $limitArticles && (
          <div className="mt-8 text-center">
            <button
              className="button technical-lg"
              onClick={() => set$limitArticles(false)}
            >
              View more articles +
            </button>
          </div>
        )}
      </>}

      {blogs.length === 0 && <NoPosts/>}
    </div>
  )
}

export default AllArticleList
