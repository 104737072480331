import * as React from 'react'
import * as css from './Subscribe.module.css'
import { useForm } from 'react-hubspot'

const Subscribe = () => {
	const { data, isLoading, isError, handleSubmit } = useForm({
		portalId: '9180180',
		formId: '89f9cbc0-087b-4453-9496-b6964dfd450c',
	})

	const email = React.useRef(null)

	return (
		<div>
			<p>Subscribe to the latest energy industry news, trends, and tips.</p>

			<form className={`${css.wrapper} mt-4 focus-within:ring-ft`} onSubmit={handleSubmit}>
				{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
				<label htmlFor="email-input">
					<input
						ref={email}
						onChange={(e) => (email.current = e)}
						className="input w-full !shadow-none"
						id="email-input"
						name="email"
						placeholder="Email"
						inputMode="email"
						type="email"
					/>
				</label>

				<button className="input-button font-bold">Subscribe ➞</button>
			</form>

			{isLoading ? (
				<div className="form-message">Sending...</div>
			) : isError ? (
				<div className="form-message">An error occurred. Please enter a valid email address</div>
			) : (
				data && <div className="form-message">{data.data.inlineMessage}</div>
			)}
		</div>
	)
}

export default Subscribe
